import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import AppInstallButtons, { alignItemsList } from 'components/App/InstallButtons';

import styles from './BannerTransform.module.scss';

const BannerTransform: React.FC = () => (
  <>
    <Container className={styles.main}>
      <Row className={styles.bannerRow}>
        <Col className={styles.mainText}>
          <FormattedMessage id="components.banners.bannerTransform.main" />
        </Col>
      </Row>
      <AppInstallButtons alignItems={alignItemsList.Center} />
    </Container>
  </>
);

export default BannerTransform;
