import React, { useEffect } from 'react';

import Index, { IndexProps } from 'components/Layout';
import SEO from 'components/seo';
import Slider from 'components/Slider/HomeSlider';

import PageWrapper from 'components/PageWrapper';
import HomeFeatures from 'components/HomeFeatures';
import BannerCalls from 'components/Banners/BannerCalls';
import BannerTransform from 'components/Banners/BannerTransform';

const IndexPage: React.FC<IndexProps> = ({ pageContext }) => {
  useEffect(() => {
    if (window.location.pathname === '/fr/') {
      window.location.pathname = '/accueil';
    }
    if (window.location.pathname === '/en/') {
      window.location.pathname = '/home';
    }
    if (window.location.pathname === '/') {
      if (navigator.language.includes('fr') || pageContext.language === 'fr') {
        window.location.pathname = '/accueil';
      } else window.location.pathname = '/home';
    }
  }, []);

  return (
    <Index pageContext={pageContext}>
      <SEO pageTitleTermPath="home" />
      <Slider />
      <PageWrapper withBottomBackground>
        <HomeFeatures />
      </PageWrapper>
      <BannerTransform />
      <BannerCalls />
    </Index>
  );
};

export default IndexPage;
