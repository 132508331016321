import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getOnoffB2BLink } from 'helpers/utils/url';

import FindMoreButton from './FindOutMoreButton';
import styles from './BannerCalls.module.scss';

const BannerCalls: React.FC = () => {
  const { locale } = useIntl();
  const url = getOnoffB2BLink(locale);

  const demoButton = (
    <FindMoreButton
      title="components.banners.bannerCalls.button"
      url={url}
    />
  );

  return (
    <>
      <Container className={styles.main}>
        <Row>
          <Col sm="8" md="9" lg="9" xl="10">
            <h2 className={styles.title}>
              <FormattedMessage id="components.banners.bannerCalls.title" />
            </h2>
            <p className={styles.description}>
              <FormattedMessage id="components.banners.bannerCalls.description" />
            </p>
          </Col>
          <Col sm="4" md="3" lg="3" xl="2" className={styles.buttons}>
            {demoButton}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BannerCalls;
