import React from 'react';
import classNames from 'classnames';

import styles from './Pagination.module.scss';

export enum Alignment {
  Left = 'left',
  Center = 'center'
}

interface PaginationProps {
  currentSlide: number;
  slideCount: number;
  goToSlide: (slide: number) => void;
  alignContent?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  currentSlide,
  slideCount,
  goToSlide,
  alignContent = Alignment.Left,
}) => {
  const alignCl = alignContent === Alignment.Center
    ? styles.paginationCentered : '';
  return (
    <div
      className={`${styles.paginationContainer} ${alignCl}`}
    >
      {[...Array(slideCount)].map((value, index) => (
        <div
          className={styles.dotContainer}
          onClick={(): void => goToSlide(index)}
          onKeyPress={(): void => goToSlide(index)}
          role="button"
          tabIndex={0}
          aria-label={`Go to slide ${index}`}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <div className={classNames(styles.dot, { [styles.active]: currentSlide === index })} />
        </div>
      ))}
    </div>
  );
};

export default Pagination;
