import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ActionLinkButton from 'components/UI/Buttons/ActionLinkButton';
import SwippablePage from 'components/UI/SwippablePage';
import { getDownloadAppLink } from 'helpers/utils/url';

import AppInstallButtons from 'components/App/InstallButtons';
import styles from '../../Core/Slider.module.scss';

interface SliderPageProps {
  id: number;
  title: string;
  description: string;
  onSwipe: (direction: string) => void;
}

const SliderPage: React.FC<SliderPageProps> = ({
  children,
  id,
  title,
  description,
  onSwipe,
}) => {
  const { locale } = useIntl();
  const url = getDownloadAppLink(locale);
  const SignUpButton = (
    <div className={styles.slideButton}>
      <ActionLinkButton
        url={url}
        customStyle={{ padding: '8px 20px' }}
      >
        <FormattedMessage id="components.slider.buttonJoin" />
      </ActionLinkButton>
    </div>
  );
  const buttonsView = id === 1 ? <div className={styles.installButtons}><AppInstallButtons /></div>
    : SignUpButton;
  return (
    <div
      className={styles.slideContainer}
      key={id}
    >
      <SwippablePage id={id} onSwipe={onSwipe}>
        <div className={styles.slide}>
          <div className={styles.slideContent}>
            <h2 className={styles.slideTitle}>
              <FormattedMessage id={title} />
            </h2>
            <p className={styles.slideDescription}>
              <FormattedMessage id={description} />
            </p>
            <div className={styles.slideButtons}>
              {buttonsView}
            </div>
          </div>
          <div className={styles.slideImage}>
            {children}
          </div>
        </div>
      </SwippablePage>
    </div>
  );
};

export default SliderPage;
