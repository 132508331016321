import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'react-intl';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import { getLocale, Locales } from 'helpers/utils/locale';
import GeneralSettings from 'helpers/constants/settings';

import Feature from './Feature';
import FeaturePhoneSlider from './FeaturePhoneSlider';
import styles from './HomeFeatures.module.scss';

const onePhoneColOrder = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 1,
    xl: 1,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
  },
};

const oneAppColOrder = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

const oneSystemColOrder = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

const HomeFeatures: React.FC = () => {
  const { locale } = useIntl();
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const isMobileDevice = (isMobileOS && !isTablet())
      || window.innerWidth > GeneralSettings.MOBILE_VIEW.EXPLAIN_VIDEO;
    setMobile(isMobileDevice);
  }, []);

  const {
    imageFeatureOnePhone,
    imageFeatureApplication,
    imageFeatureApplicationFR, // @todo add real image
    imageFeatureSystem,
  } = useStaticQuery(
    graphql`
        query {
          imageFeatureOnePhone: file(relativePath: { eq: "home/onephone.png" }) {
                childImageSharp {
                    fluid(maxWidth: 794) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
          imageFeatureApplication: file(relativePath: { eq: "home/application.png" }) {
              childImageSharp {
                  fluid(maxWidth: 794) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
            }
          }
          imageFeatureApplicationFR: file(relativePath: { eq: "home/application.png" }) {
              childImageSharp {
                  fluid(maxWidth: 794) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
            }
          }
          imageFeatureSystem: file(relativePath: { eq: "home/system.png" }) {
            childImageSharp {
                fluid(maxWidth: 794) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
          }
        }   
      }
    `,
  );

  const appImage = getLocale(locale) === Locales.En
    ? imageFeatureApplication : imageFeatureApplicationFR;
  const howItWorksPad = isMobile ? { paddingTop: '30px', paddingBottom: '30px' } : {};

  return (
    <Container className={styles.main}>
      <Feature
        id="howItWorks"
        colOrder={oneSystemColOrder}
        title="components.home.featureHowItWorks.title"
        description="components.home.featureHowItWorks.description"
        image={imageFeatureSystem}
        customContentStyle={howItWorksPad}
        buttonEnabled={false}
        isMirrored
      />
      <Feature
        id="privacy"
        colOrder={onePhoneColOrder}
        title="components.home.featurePrivacy.title"
        description="components.home.featurePrivacy.description"
        image={imageFeatureOnePhone}
        buttonEnabled
      />
      <FeaturePhoneSlider
        id="phoneSlider"
        title="components.phoneSlider.title"
        description="components.phoneSlider.description"
      />
      <Feature
        id="noContract"
        colOrder={oneAppColOrder}
        title="components.home.featureNoContract.title"
        description="components.home.featureNoContract.description"
        image={appImage}
        buttonTranslation="components.home.letsGo"
        buttonEnabled
        isMirrored
      />
    </Container>
  );
};

export default HomeFeatures;
