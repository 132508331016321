import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import PhoneSlider from 'components/Slider/PhoneSlider';

import { TowColumnsOrderType } from 'types/columnMeasure';
import cmnStyles from 'styles/pages.module.scss';
import featureStyles from '../HomeFeatures.module.scss';
import styles from './FeaturePhoneSldier.module.scss';

interface FeaturePhoneSliderProps {
  id?: string;
  colOrder?: TowColumnsOrderType;
  title: string;
  description: string;
}

const defaultColumns = {
  left: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 1,
    xl: 1,
  },
  right: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
  },
};

const FeaturePhoneSlider: React.FC<FeaturePhoneSliderProps> = ({
  colOrder = defaultColumns,
  title,
  description,
}) => {
  const colText = `${cmnStyles.alignSectionVerticallyCenter} ${styles.header}`;
  return (
    <Container className={styles.container}>
      <Row className={styles.main}>
        <Col
          xs={{ span: 12, order: colOrder.left.xs }}
          sm={{ span: 12, order: colOrder.left.sm }}
          md={{ span: 12, order: colOrder.left.md }}
          lg={{ span: 12, order: colOrder.left.lg }}
          xl={{ span: 12, order: colOrder.left.xl }}
          className={colText}
        >
          <h2 className={`${featureStyles.featureTitle} ${styles.header}`}>
            <FormattedMessage id={title} />
          </h2>
          <p className={`${featureStyles.featureDescription} ${styles.titleBody}`}>
            <FormattedMessage id={description} />
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneSlider />
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturePhoneSlider;
