import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import { getLocale } from 'helpers/utils/locale';
import GeneralSettings from 'helpers/constants/settings';

import SliderComponent from 'components/UI/Slider';
import { SwipeDir } from 'components/UI/SwippablePage';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';

import ControlButton, { ArrowsMobPositions } from '../Core/ControlButton';
import Pagination, { Alignment } from '../Core/Pagination';
import SliderPage from './SliderPhonePage';
import styles from './PhoneSlider.module.scss';

const PhoneSlider: React.FC = () => {
  const [isMobile, setMobile] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [sliderHover, setSliderHover] = useState(false);
  const forwardRef = React.useRef<HTMLButtonElement>(null);
  const backRef = React.useRef<HTMLButtonElement>(null);
  const { locale } = useIntl();

  const updateWidth = (): void => {
    const isMobileDevice = (isMobileOS() && !isTablet)
    || window.innerWidth < GeneralSettings.MOBILE_VIEW.PHONE_SLIDER;
    setMobile(isMobileDevice);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
  }, []);

  const {
    slideImg1,
    slideImg1FR,
    slideImg2,
    slideImg2FR,
    slideImg3,
    slideImg3FR,
    slideMobImg1,
    slideMobImg1FR,
    slideMobImg2,
    slideMobImg2FR,
    slideMobImg3,
    slideMobImg3FR,
  } = useStaticQuery(
    graphql`
        query {
            slideImg1: file(relativePath: { eq: "phoneslider/image-1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1400) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg1FR: file(relativePath: { eq: "phoneslider/image-1_fr.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1400) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg2: file(relativePath: { eq: "phoneslider/image-2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1400) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg2FR: file(relativePath: { eq: "phoneslider/image-2_fr.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1400) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
            }
            slideImg3: file(relativePath: { eq: "phoneslider/image-3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1400) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            slideImg3FR: file(relativePath: { eq: "phoneslider/image-3_fr.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1400) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          }
          slideMobImg1: file(relativePath: { eq: "phoneslider/mob/image-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 977) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
            }
          }
          slideMobImg1FR: file(relativePath: { eq: "phoneslider/mob/image-1_fr.png" }) {
            childImageSharp {
                fluid(maxWidth: 977) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
            }
          }
          slideMobImg2: file(relativePath: { eq: "phoneslider/mob/image-2.png" }) {
              childImageSharp {
                  fluid(maxWidth: 977) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          }
          slideMobImg2FR: file(relativePath: { eq: "phoneslider/mob/image-2_fr.png" }) {
            childImageSharp {
                fluid(maxWidth: 977) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
            }
          }
          slideMobImg3: file(relativePath: { eq: "phoneslider/mob/image-3.png" }) {
              childImageSharp {
                  fluid(maxWidth: 977) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          }
          slideMobImg3FR: file(relativePath: { eq: "phoneslider/mob/image-3_fr.png" }) {
            childImageSharp {
                fluid(maxWidth: 977) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
            }
        }
        }
    `,
  );

  let imgSlide1 = getLocale(locale) === 'en' ? slideImg1 : slideImg1FR;
  let imgSlide2 = getLocale(locale) === 'en' ? slideImg2 : slideImg2FR;
  let imgSlide3 = getLocale(locale) === 'en' ? slideImg3 : slideImg3FR;

  if (isMobile) {
    imgSlide1 = getLocale(locale) === 'en' ? slideMobImg1 : slideMobImg1FR;
    imgSlide2 = getLocale(locale) === 'en' ? slideMobImg2 : slideMobImg2FR;
    imgSlide3 = getLocale(locale) === 'en' ? slideMobImg3 : slideMobImg3FR;
  }

  const onSliderEnter = (): void => {
    setSliderHover(true);
  };

  const onSliderLeave = (): void => {
    setSliderHover(false);
  };

  const swipeSlide = (direction: string): void => {
    if (direction === SwipeDir.Left) {
      if (forwardRef.current !== null) {
        forwardRef.current.click();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (backRef.current !== null) {
        backRef.current.click();
      }
    }
  };

  return (
    <section
      className={styles.container}
      onMouseEnter={onSliderEnter}
      onMouseLeave={onSliderLeave}
    >
      <div className={styles.slider}>
        <SliderComponent
          autoplay={autoPlay}
          renderControls={(
            next,
            previous,
            goTo,
            currentSlide,
            totalSlides,
          ): React.ReactNodeArray => [
            <ControlButton
              key="back-button"
              type="back"
              reference={backRef}
              mobileVisible
              arrowPosMobile={ArrowsMobPositions.Top}
              isHoverEffect={!isMobile}
              isSliderHovered={sliderHover}
              onClick={(): void => {
                setAutoPlay(false);
                previous();
              }}
            />,
            <ControlButton
              key="forward-button"
              type="forward"
              reference={forwardRef}
              mobileVisible
              arrowPosMobile={ArrowsMobPositions.Top}
              isHoverEffect={!isMobile}
              isSliderHovered={sliderHover}
              onClick={(): void => {
                setAutoPlay(false);
                next();
              }}
            />,
            <Pagination
              key="forward-pagination"
              currentSlide={currentSlide}
              slideCount={totalSlides}
              goToSlide={(slide): void => {
                setAutoPlay(false);
                goTo(slide);
              }}
              alignContent={Alignment.Center}
            />,
          ]}
          useBorders
        >

          <SliderPage
            id={1}
            title="components.phoneSlider.slide_1.title"
            onSwipe={swipeSlide}
          >
            <GatsbyImage fluid={imgSlide1.childImageSharp.fluid} />
          </SliderPage>

          <SliderPage
            id={2}
            title="components.phoneSlider.slide_2.title"
            onSwipe={swipeSlide}
          >
            <GatsbyImage fluid={imgSlide2.childImageSharp.fluid} />
          </SliderPage>

          <SliderPage
            id={3}
            title="components.phoneSlider.slide_3.title"
            onSwipe={swipeSlide}
          >
            <GatsbyImage fluid={imgSlide3.childImageSharp.fluid} />
          </SliderPage>

        </SliderComponent>
      </div>
    </section>
  );
};

export default PhoneSlider;
