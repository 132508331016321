import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import GooglePlayButton from 'images/installbuttons/google-play.svg';
import IOSButton from 'images/installbuttons/ios.svg';

import styles from './InstallButtons.module.scss';
import AppInstallButton from './InstallButton';

type AppInstallButtonProps = {
  alignItems?: string;
}

export enum alignItemsList {
  Left = 'left',
  Center = 'center'
}

const AppInstallButtons: React.FC<AppInstallButtonProps> = ({
  alignItems = alignItemsList.Left,
}) => {
  const itemAlignCl = alignItems === alignItemsList.Center ? styles.containerCenter : '';
  return (
    <Container className={`${styles.container}`}>
      <Row className={styles.buttons}>
        <Col
          className={`${styles.buttonsCol} ${itemAlignCl}`}
        >
          <ul>
            <li>
              <AppInstallButton>
                <img src={IOSButton} alt="IOS" />
              </AppInstallButton>
            </li>
            <li>
              <AppInstallButton>
                <img src={GooglePlayButton} alt="Android" />
              </AppInstallButton>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default AppInstallButtons;
