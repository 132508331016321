import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { getDownloadAppLink } from 'helpers/utils/url';

const AppInstallButton: React.FC = ({
  children,
}) => {
  const { locale } = useIntl();
  const url = getDownloadAppLink(locale);
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default AppInstallButton;
