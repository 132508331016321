/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';

import styles from './ControlButton.module.scss';
import arrow from './arrow.svg';

export enum ArrowsMobPositions {
  Top = 'top',
  Center = 'center'
}

interface ControlButtonProps {
  type: 'forward' | 'back';
  onClick: () => void;
  mobileVisible?: boolean;
  arrowPosMobile?: string;
  reference?: React.RefObject<HTMLButtonElement> | null;
  isHoverEffect?: boolean;
  isSliderHovered?: boolean;
}

const ControlButton: React.FC<ControlButtonProps> = ({
  type,
  onClick,
  mobileVisible = false,
  arrowPosMobile = ArrowsMobPositions.Top,
  reference,
  isHoverEffect = false,
  isSliderHovered = false,
}) => {
  const [arrowMidMobCl, setArrowMobileCl] = useState('');
  useEffect(() => {
    const cl = isMobileOS() && !isTablet() ? styles.arrowsPicMidMobile : '';
    if (arrowPosMobile === ArrowsMobPositions.Center) {
      setArrowMobileCl(cl);
    }
  }, []);

  let itemClass = classNames(styles.button, styles[type]);
  itemClass = mobileVisible ? itemClass : `${itemClass} ${styles.invisible}`;

  switch (arrowPosMobile) {
    case ArrowsMobPositions.Top:
      itemClass += ` ${styles.arrowsPicTop}`;
      break;
    case ArrowsMobPositions.Center:
    default:
      itemClass += ` ${styles.arrowsPicMiddle}`;
      break;
  }

  // if hover effect display only if user hovers on whole slider
  if (isHoverEffect && !isSliderHovered) {
    return null;
  }

  return (
    <button
      type="button"
      ref={reference}
      onClick={onClick}
      className={`${itemClass} ${arrowMidMobCl}`}
      aria-label="Previous slide"
    >
      <img src={arrow} className={styles.icon} alt="" />
    </button>
  );
};

export default ControlButton;
