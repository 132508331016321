import React from 'react';
import { FormattedMessage } from 'react-intl';

import SwippablePage from 'components/UI/SwippablePage';
// import Links from 'helpers/constants/links';
import cmnStyles from '../PhoneSlider.module.scss';
import './SliderPhonePage.scss';

interface SliderPageProps {
  id: number;
  title: string;
  onSwipe: (direction: string) => void;
}

const SliderPhonePage: React.FC<SliderPageProps> = ({
  children,
  id,
  title,
  onSwipe,
}) => (
  <div
    className={cmnStyles.slideContainer}
    key={id}
  >
    <SwippablePage id={id} onSwipe={onSwipe}>
      <div className={cmnStyles.slide}>
        <div className={cmnStyles.slideImage}>
          {children}
        </div>
        <div className={cmnStyles.slideContent}>
          <p className={cmnStyles.slideTitle}>
            <FormattedMessage
              id={title}
              values={{
                span: (...chunks: string[]): JSX.Element => (
                  <span
                    className={`slide${id}`}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </SwippablePage>
  </div>
);

export default SliderPhonePage;
